import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message,Image } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./index.scss"
import { getRequest } from '../../utils/request';

const buttonLayout = {
  wrapperCol: {
    offset: 10,
    span: 16
  }
}

const App: React.FC = () => {

  const [form] = Form.useForm()
  const [registerForm] = Form.useForm()


  const [messageApi, contextHolder] = message.useMessage();
  const [count, setCount] = useState(60)
  const [liked, setLiked] = useState(true)
  const [iscard, setIsCard] = useState(true)
  const key = "login"
  // 成功提示
  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
    });
  };

  // router Hooks
  const navigate = useNavigate()

  const submitData = async () => {
    message.open({
      key,
      type: 'loading',
      content: '正在登录中...',
    });
    const data = form.getFieldsValue()
    // console.log(data)
    axios.get("/work/wxLogin",{
      params :  {...data, type:"非上门"}
    }).then((res:any) => {
      console.log(res)
      if (res.data.code == 0) {
        localStorage.setItem("token", res.headers.authorization)
        localStorage.setItem("isLogin", "1")
        localStorage.setItem("city", res.data.data.city)
        localStorage.setItem("userId", res.data.data.id)
		const roleName=res.data.data.roleName;
		if(roleName&&roleName[0]){
			localStorage.setItem("roleName", roleName[0])
		}
        message.open({
          key,
          type: 'success',
          content: '登录成功!',
          duration: 1,
        });
        if(roleName&&roleName[0]==='huiju'){
          navigate("/sfthome/1/10/0")
        }else{
          navigate("/home/1/10/0")
        }
      }else{
        message.open({
          key,
          type: 'error',
          content: res.data.message,
          duration: 1,
        });
        if(res.data.message == "用户需要注册"){
          setIsCard(false)
        }
      }
    })
  };

  const submitRegister = async  () => {
    message.open({
      key,
      type: 'loading',
      content: '正在注册中...',
    });
    const data = form.getFieldsValue()
    axios.get("/work/master/user/verCode",{
      params :  {...data}
    }).then((res:any) => {

      if (res.data.code == 0) {
        console.log(res.data.id,res.data.city)
        localStorage.setItem("token", res.headers.authorization)
        localStorage.setItem("isLogin", "1")
        localStorage.setItem("city", res.data.city)
        localStorage.setItem("userId", res.data.id)
        message.open({
          key,
          type: 'success',
          content: '注册成功!',
          duration: 1,
        });
        navigate("/home/1/10/0")
      }else{
        message.open({
          key,
          type: 'error',
          content: res.data.message,
          duration: 1,
        });
      }
    })
  };



  const getCode = async () => {
    const data = form.getFieldsValue()

    if(!data.phone){
      message.error("请输入正确的手机号!")
      return
    }
    const timer = setInterval(() => {
      setCount(old => {
        if (old === 1) {
          setLiked(true)
          clearInterval(timer)
          return 60
        } else {
          return old - 1
        }
      })
      setLiked(false)
    }, 1000);
    let res = await getRequest("/work/master/user/getCode", data)
    if (res.code === 0) {
      success("获取验证码成功")
    }
  }


  return (
    <div className='login-form'>
      {contextHolder}
      <div style={{"textAlign":"center", 'marginBottom': "10px"}}>
      <h1>登录客服</h1>
      </div>
     {
      iscard ? ( <Form
        name="normal_login"
        form={form}
        onFinish={submitData}
      >
        <Form.Item
          name="phone"
          rules={[{ required: true, message: '请输入手机号!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="手机号" />
        </Form.Item>
        <div className='code-box'>
          <Form.Item
            name="verCode"
          // rules={[{ required: true, message: '请输入验证码!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="验证码"
            />
          </Form.Item>
          {
            liked ? (<div className='get-code' onClick={getCode}>获取验证码</div>) : (<div className='get-code'>{count}秒</div>)
          }
        </div>

        <Form.Item>
          <Button type="primary" className='login-button' htmlType="submit">
            登录
          </Button>
          {/* <p className='login-remind'>没有账号，立即注册</p> */}
        </Form.Item>
      </Form>):(  <Form
        name="normal_login"
        form={registerForm}
        onFinish={submitRegister}
      >
        <Form.Item
          name="phone"
          rules={[{ required: true, message: '请输入手机号!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="手机号" />
        </Form.Item>
        <div className='code-box'>
          <Form.Item
            name="code"
          // rules={[{ required: true, message: '请输入验证码!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="验证码"
            />
          </Form.Item>
          {
            liked ? (<div className='get-code' onClick={getCode}>获取验证码</div>) : (<div className='get-code'>{count}秒</div>)
          }
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            注册
          </Button>
        </Form.Item>
      </Form>)
     }
          {/* 注册 */}

    </div>
  );
};

export default App;
